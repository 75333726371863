<template lang="pug">
EcreativOrganismsMediaArticle(
  :title="aboutSchema.title"
  :imageMobile="aboutSchema.imageMobile"
  :imageDesktop="aboutSchema.imageDesktop"
  :intro="aboutSchema.intro"
  :content="aboutSchema.content"
)
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EcreativOrganismsMediaArticle from '../organisms/MediaArticle.vue';

const aboutSchema = {
  title: 'About Sample Title',
  imageMobile: {
    src: 'https://picsum.photos/200/200',
    alt: 'About Sample Community Mobile',
  },
  imageDesktop: {
    src: 'https://picsum.photos/200/300',
    alt: 'About Sample Community Desktop',
  },
  intro:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel aliquam eros, id tincidunt lorem. In bibendum convallis nisl, vel bibendum augue faucibus sed.',
  content:
    'Ut eu eros vel nunc efficitur mattis. Nullam rutrum facilisis nisi, at laoreet justo volutpat sed. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque vehicula libero.',
};
</script>
