<template lang="pug">
EcreativAtomsButton.collapseButton.pe-4(
  v-toggle-collapse="toggleId"
  v-bind="{ context, isUnderLine, size, type, hoverColor, fontWeight, text, linkColor}"
  :class="{ 'isFirstControl': isControl }"
)
  template(v-if="hasIcon" #icon)
    EcreativAtomsSvgIcon(
      v-bind="{ iconName, isCustom, context: iconContext, iconSize }"
    )
</template>

<script setup>
import { defineProps, computed } from 'vue';
import EcreativAtomsSvgIcon from '../atoms/SvgIcon.vue';
import EcreativAtomsButton from '../atoms/Button.vue';

const props = defineProps({
  context: {
    type: String,
    default: 'none',
  },
  hasIcon: {
    type: Boolean,
    default: true,
  },
  isUnderLine: {
    type: String,
    default: 'underline',
  },
  size: {
    type: String,
    default: 'sm',
  },
  type: {
    type: String,
    default: 'IsButton',
  },
  hoverColor: {
    type: String,
    default: 'var:primary',
  },
  collapsePrefix: {
    type: String,
    required: true,
  },
  isControl: {
    type: Boolean,
    default: true,
  },
  collapseId: {
    type: Number,
    default: 0,
  },
  fontWeight: {
    type: String,
    default: 'bold',
  },
  text: {
    type: String,
    required: true,
    description: 'Text to be displayed on the button',
  },
  // Icon related props
  iconName: {
    type: String,
    default: 'chevronDown',
  },
  isCustom: {
    type: Boolean,
    default: true,
  },
  linkColor: {
    type: String,
    default: '',
  },
  iconContext: {
    type: String,
    default: 'white',
  },
  iconSize: {
    type: String,
    default: 'sm',
  },
});

const toggleId = computed(() => {
  return `${props.collapsePrefix}-${props.collapseId}`;
});
</script>

<style lang="scss">
.collapseButton {
  position: relative;
  .iconWrapper {
    position: absolute;
    top: 50%;
    right: 0;
    transition: all 0.15s ease 0s;
    transform: translateY(-50%) rotate(0deg);
  }

  &[aria-expanded='true'] {
    .iconWrapper {
      color: $secondary;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &:not(.isFirstControl)[aria-expanded='true'] {
    color: transparent;
    visibility: hidden;
    pointer-events: none;
  }
}
</style>
