<template lang="pug">
.glide.mt-3.mt-md-0(v-if="articles")
  .glide__track(data-glide-el='track')
    ul.glide__slides
      li.glide__slide(v-for='(item, index) in articles' :key='index')
        EcreativMoleculesCardsArticleCard.glide__image(
          :id='item.id',
          :image='item.image',
          :content="item.truncateContent"
          :truncateContent="''"
          :title="item.title"
          :link="item.slug"
          :date="''"
          category='articles'
          linkLabel="Learn More"
        )
  .glide__arrows.top-30.w-100.position-absolute(data-glide-el='controls')
    button.glide__arrow.glide__arrow--left(data-glide-dir='<')
      EcreativAtomsSvgIcon(
        iconName='chevronLeft',
        isCustom=true,
        iconSize='md'
      )
    button.glide__arrow.glide__arrow--right(data-glide-dir='>')
      EcreativAtomsSvgIcon(
        iconName='chevronRight',
        isCustom=true,
        iconSize='md'
      )
</template>

<script setup>
import { defineProps, watch, onMounted, nextTick, toRefs } from 'vue';
import Glide, {
  Swipe,
  Images,
  Controls,
  Autoplay,
} from '@glidejs/glide/dist/glide.modular.esm.js';
import EcreativMoleculesCardsArticleCard from '../molecules/Cards/ArticleCard.vue';
import EcreativAtomsSvgIcon from '../atoms/SvgIcon.vue';

const props = defineProps({
  articles: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const { articles } = toRefs(props);

onMounted(() => {
  if (articles.value?.length > 0) {
    nextTick(() => {
      initiateGlide();
    });
  }
});

let glideInstance = null;

const initiateGlide = () => {
  if (
    document.querySelector('.glide') &&
    document.querySelector('.glide').classList.contains('glide--init')
  ) {
    glideInstance.update();
    return;
  }

  if (glideInstance) {
    glideInstance.destroy();
    glideInstance = null;
  }

  if (document.querySelector('.glide')) {
    glideInstance = new Glide('.glide', { autoplay: 3000, perView: 1 });

    glideInstance.on('build.after', () => {
      document.querySelector('.glide').classList.add('glide--init');
    });

    glideInstance.mount({ Swipe, Images, Controls, Autoplay });
    glideInstance.update();
  }
};

watch(
  () => articles.value,
  (newVal) => {
    if (newVal?.length && process.client) {
      nextTick(() => {
        initiateGlide();
      });
    }
  },
  { deep: true, immediate: true },
);
</script>
