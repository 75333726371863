<template lang="pug">
EcreativOrganismsPresentingLatestArticlesSliderAndCallToLearnMore(:articles="generatedData")
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { type ArticleModel, placeholderArticleModel } from 'typed-schemas';
import EcreativOrganismsPresentingLatestArticlesSliderAndCallToLearnMore from '../organisms/PresentingLatestArticlesSliderAndCallToLearnMore.vue';

const props = defineProps({
  articleCount: {
    type: Number,
    default: 1,
  },
});

const defaultData: Partial<ArticleModel> = placeholderArticleModel;

const generatedData = computed(() => {
  return Array.from({ length: props.articleCount }, (_, index) => ({
    ...defaultData,
    index,
  }));
});
</script>
