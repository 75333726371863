<template lang="pug">
EcreativOrganismsAboutSocialAndSubscribeSidebar(:data="sidebarSchema")
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EcreativOrganismsAboutSocialAndSubscribeSidebar from '../organisms/AboutSocialAndSubscribeSidebar.vue';

const sidebarSchema = {
  about: {
    title: 'Placeholder Title',
    text: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  twitter: {
    title: 'Placeholder Title',
  },
  followUs: {
    title: 'Placeholder Title',
  },
};
</script>
