<template lang="pug">
section.mb-5
  DynamicForm(
    :save-firebase="true",
    :send-email="false",
    :schema="formSchema",
    firebase-collection="mailingList",
    form-name="Mailing List",
    form-ref="contact",
    :big-form="false",
    :secondary="true"
  )
    template(#thankyou="slotProps")
      ThankYouMessage(
        :name="slotProps.userData.inputName",
        :title="slotProps.userData.inputTitle",
        signature="The Outreach and Public Relations Department",
        contactNumber="020 8687 7871"
      )
    template(#submitAgainText)
      span.text-white.text-uppercase.fw-bold Submit again
    template(#submitText)
      span.text-white.text-uppercase.fw-bold Submit
    template(#loader)
      EcreativAtomsSpinner
    template(#introSection)
      EcreativAtomsHeading.FormBuilder__title(tag="h2" size="3" context="dark") Subscribe
    template(#privacyPolicy)
      p.FormBuilder__privacy
        b I have read and agreed to the&nbsp;
          a.text-secondary.ml-2(
            target="_blank",
            href="https://docs.trueislam.co.uk/ama-uk-privacy-notice.pdf"
          ) Privacy Policy
</template>

<script setup>
import * as Yup from 'yup';

const formSchema = [
  {
    title: '',
    description: '',
    fields: [
      {
        type: 'text',
        name: 'inputName',
        as: 'input',
        placeholder: 'Your Name',
        labelText: 'Full Name',
        rules: Yup.string().required(),
      },
      {
        type: 'text',
        name: 'inputEmail',
        as: 'input',
        placeholder: 'Your Email',
        labelText: 'Email',
        rules: Yup.string().email().required(),
      },
    ],
  },
];
</script>
