<template lang="pug">
// Slot for custom top-button if needed
slot(name="top-button")

// Main collapse component
EcreativMoleculesCollapse(:id="sectionId")
  // The paragraph for this section
  EcreativAtomsParagraph(fontWeight="bold") {{ sectionContent }}

  // Conditionally render the read more button if there's a next section
  EcreativMoleculesCollapseButton.p-0.text-white.fw-bold.text-decoration-underline(
    v-if="hasNextSection"
    :collapsePrefix="collapsePrefix"
    :collapseId="nextCollapseId"
    text="Read more…"
    hoverColor="var:secondary"
    linkColor="white"
    context="link"
    :hasIcon="false"
    :isControl="false"
  )

  // Render nested sections if they exist
  EcreativMoleculesNestedCollapse(
    v-if="hasNextSection"
    :key="nextCollapseId"
    :sections="sections"
    :startId="nextCollapseId"
    :collapsePrefix="collapsePrefix"
  )
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import EcreativMoleculesCollapse from '../molecules/Collapse.vue';
import EcreativAtomsParagraph from '../atoms/Paragraph.vue';
import EcreativMoleculesCollapseButton from '../molecules/CollapseButton.vue';
import EcreativMoleculesNestedCollapse from '../molecules/NestedCollapse.vue';

// Define props with their respective descriptions
const props = defineProps({
  sections: {
    type: Array,
    required: true,
    default: () => [],
    description: 'Array of sections to be rendered',
  },
  startId: {
    type: Number,
    default: 0,
    description: 'The index of the current section in the sections array',
  },
  collapsePrefix: {
    type: String,
    required: true,
    default: 'collapse',
    description: 'Collapse identifier prefix',
  },
});

// Compute the content of the current section
const sectionContent = computed(() => {
  return props.sections[props.startId] || '';
});

// Compute the current section's ID
const sectionId = computed(() => {
  return `${props.collapsePrefix}-${props.startId}`;
});

// Compute the next collapse ID based on the current section index
const nextCollapseId = computed(() => {
  return props.startId + 1;
});

// Check if there's a next section to render
const hasNextSection = computed(() => {
  return props.sections.length > props.startId + 1;
});
</script>
