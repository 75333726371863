<template lang="pug">
EcreativOrganismsListArticlesOneColumn(:articles="generatedArticles")
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EcreativOrganismsListArticlesOneColumn from '../organisms/ListArticlesOneColumn.vue';

const props = defineProps({
  articleCount: {
    type: Number,
    default: 1,
  },
});

const articleSchema = {
  title: 'Title Lorem ipsum dolor sit amet.',
  image: 'https://picsum.photos/200/300',
  link: '#',
  content:
    'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid officiis hic aliquam distinctio est possimus. Sequi, repudiandae numquam. Harum quaerat adipisci, accusantium nostrum ex minima dolore omnis ipsa eaque ab.',
};

const generatedArticles = computed(() => {
  return Array.from({ length: props.articleCount }, (_, index) => ({
    ...articleSchema,
    index,
  }));
});
</script>
