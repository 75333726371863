<template lang="pug">
EcreativTemplatesMain
  template(#header)
    EcreativOrganismsPresentingLogoAndCallToNavigateMainDirectory(:mainNavLinks="mainNavLinks" :mainNavLinksMobile="mainNavLinksMobile" :secondNavLinks="secondNavLinks")
  template(#introducing)
    EcreativOrganismsPresentingIntroAndCallToLearnMore(:headingText="introData.headingText" :paragraphText="introData.paragraphText" :buttonText="introData.buttonText")
  template(#slider)
    EcreativOrganismsPresentingLatestArticlesSliderAndCallToLearnMore(:articles="sliderArticles")
  template(#latestArticles)
    EcreativOrganismsPresentingLatestArticlesAndCallToLoadMore(:articles="injectedArticles")
  template(#beliefs)
    EcreativOrganismsListArticlesOneColumn(:articles="elevenPoints")
  template(#intro)
    EcreativOrganismsExpandableMediaArticle(
      :title="messiahInfo.title"
      :caption="messiahInfo.caption"
      :content="messiahInfo.content"
      :imageSrc="messiahInfo.imageSrc"
      :imageAlt="messiahInfo.imageAlt"
      :introText="messiahInfo.introText"
      :learnMoreText="messiahInfo.learnMoreText"
    )
  template(#about)
    EcreativOrganismsMediaArticle(
      :title="aboutSchema.title"
      :imageMobile="aboutSchema.imageMobile"
      :imageDesktop="aboutSchema.imageDesktop"
      :intro="aboutSchema.intro"
      :content="aboutSchema.content"
    )
  template(#sidebar)
    EcreativOrganismsAboutSocialAndSubscribeSidebar(:data="sidebarSchema")
  template(#footer)
    EcreativOrganismsFooterNavigationAndLegal(:footerLinks="footerLinks", :legalLinks="legalLinks")
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';

const mainNavLinks = inject('mainNavLinks', ref([]));
const mainNavLinksMobile = inject('mainNavLinksMobile', ref([]));
const secondNavLinks = inject('secondNavLinks', ref([]));
const aboutSchema = inject('aboutSchema', ref([]));
const messiahInfo = inject('messiahInfo', ref([]));
const sidebarSchema = inject('sidebarSchema', ref([]));
const introData = inject('introData', ref([]));
const footerLinks = inject('footerLinks', ref([]));
const legalLinks = inject('legalLinks', ref([]));
const sliderArticles = inject('latestArticles', ref([]));
const elevenPoints = inject('elevenPoints', ref([]));
const injectedArticles = inject('articlesData', ref([]));

useHead({
  title: 'Introducing Ahmadiyyat',
});
</script>
